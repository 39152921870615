import React, { useState, useEffect } from 'react';

import playersFr from './Var';



function AppFr() {

  const [usedPlayers, setUsedPlayers] = useState(Array(playersFr.length).fill(false));

  const [currentPlayerIndex, setCurrentPlayerIndex] = useState(0);
  const [guess, setGuess] = useState('');
  const [isCorrectGuess, setIsCorrectGuess] = useState(false);
  const [isIncorrectGuess, setIsIncorrectGuess] = useState(false);
  const [showHints, setShowHints] = useState(false);
  const [hints, setHints] = useState([]);
  const [areHintsExhausted, setAreHintsExhausted] = useState(false);
  const [showAllHintsUsedMessage, setShowAllHintsUsedMessage] = useState(false);

  useEffect(() => {
    setCurrentPlayerIndex(Math.floor(Math.random() * playersFr.length));
  }, []);

  const currentPlayer = playersFr[currentPlayerIndex];

  const handleGuessChange = (event) => {
    setGuess(event.target.value);
  };

  const handleGuessSubmit = (event) => {
    event.preventDefault();

    const lowercaseGuess = guess.toLowerCase();
    const validNames = currentPlayer.name.map((name) => name.toLowerCase());

    if (validNames.includes(lowercaseGuess)) {
      setIsCorrectGuess(true);
      setIsIncorrectGuess(false);
      setGuess('');
      setShowHints(false);
      setHints([]);
      setAreHintsExhausted(false); 

      setTimeout(() => {
        const updatedUsedPlayers = [...usedPlayers];
        updatedUsedPlayers[currentPlayerIndex] = true;
        setUsedPlayers(updatedUsedPlayers);

        const allPlayersUsed = updatedUsedPlayers.every((usedPlayer) => usedPlayer);

        if (!allPlayersUsed) {
          let newIndex;
          do {
            newIndex = Math.floor(Math.random() * playersFr.length);
          } while (updatedUsedPlayers[newIndex]);

          setCurrentPlayerIndex(newIndex);
        }

        setIsCorrectGuess(false);
      }, 2000);
    } else {
      setIsCorrectGuess(false);
      setIsIncorrectGuess(true);
      setGuess('');

      setTimeout(() => {
        setIsIncorrectGuess(false);
      }, 2000);
    }
  };

  const handleHintClick = () => {
    if (hints.length === 2) {
      setAreHintsExhausted(true);
      setShowAllHintsUsedMessage(true);
      setTimeout(() => {
        setShowAllHintsUsedMessage(false);
      }, 2000);
    } else {
      setShowHints(true);

      const availableHints = ['nationality', 'position'];
      const remainingHints = availableHints.filter((hint) => !hints.includes(hint));
      const randomHint = remainingHints[Math.floor(Math.random() * remainingHints.length)];

      setHints([...hints, randomHint]);
    }
  };

  const allPlayersUsed = usedPlayers.every((usedPlayer) => usedPlayer);

  return (
    <div className="App">
      {allPlayersUsed ? (
        <h1>Félicitations vous avez deviné tous les joueurs !</h1>
      ) : (
        <>
          <h1>Devinez le footballeur</h1>
          <p className='result'>
          {isCorrectGuess && <p className="correct">Correct !</p>}
          {isIncorrectGuess && <p className="incorrect">Faux !</p>}</p>
          <p>
            <p>Parcours :</p>{' '}
            {currentPlayer.clubs.map((club, index) => (
              <img key={`club-logo-${index}`} src={club} alt={`club-Logo ${club}`} className="logo" />
            ))}
          </p>
          {showHints && (
            <div className="column-container">
              {hints.includes('nationality') && (
                <p className="hint">
                  Indice : Nationalité -{' '}
                  {currentPlayer.nationality.map((nationality, index) => (
                    <img
                      key={`logo-${index}`}
                      src={nationality}
                      alt={`Logo ${nationality}`}
                      className="logo-nationality"
                    />
                  ))}
                </p>
              )}
              {hints.includes('position') && (
                <p className="hint">Indice : Poste - {currentPlayer.position}</p>
              )}
            </div>
          )}
          <form onSubmit={handleGuessSubmit}>
            <input
              type="text"
              value={guess}
              onChange={handleGuessChange}
              placeholder="Entrez le nom du joueur"
            />
            <button className="guessbutton" type="submit">
              Deviner
            </button>
          </form>
          {showAllHintsUsedMessage && <p>Tous les indices ont été utilisés.</p>}
          <button
            className="tipsbutton"
            onClick={handleHintClick}
            disabled={areHintsExhausted}
          >
            Indice
          </button>
        </>
      )}
    </div>
  );
}

export default AppFr;

