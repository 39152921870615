import React from 'react';
import './Informations.css'; // Import your CSS file for styling
import guessfaux from './assets/guessfauxEn.PNG';
import guessright from './assets/guessrightEn.PNG';
import guessmessi from './assets/guessmessiEn.PNG';
import guessronaldo from './assets/guessronaldoEn.PNG';

const Informations = () => {
  return (
    <div className="information-page">
            <div className="information-content-en">

      <h2>About Guess The Football Player</h2>
      <p>
        Welcome to Guess The Football Player, a fun game that tests your knowledge of football players based on their club careers.
      </p>
      <p>
        How to play:
      </p>
      <ul>
        <li>You will see a list of clubs the player has been a part of during their career.</li>
        <li>Your task is to guess the player's name using the provided hints.</li>
        <li>Simply enter the player's last name in the text field and click "Guess".</li>
      </ul>
      <p>
          Example :
        </p>
        <img
          src={guessmessi} 
          alt="Guess Example"
          className="information-image"
        />
        <img
          src={guessfaux} 
          alt="Guess Example"
          className="information-image"
        />
        <img
          src={guessronaldo} 
          alt="Guess Example"
          className="information-image"
        />
        <img
          src={guessright} 
          alt="Guess Example"
          className="information-image"
        />

      <p>
        Good luck and have fun playing!
      </p>
      </div>
    </div>
  );
};

export default Informations;
