import realMadridLogo from './assets/realmadrid.png';
import bordeauxLogo from './assets/Bordeaux.png';
import dynamoLogo from './assets/Dynamo.png';
import juventusLogo from './assets/Juventus.png';
import tottenhamLogo from './assets/Tottenham.webp';
import bayernLogo from './assets/bayern.png';
import psgLogo from './assets/psg.webp';
import parisSGLogo from './assets/psg.webp';
import mancityLogo from './assets/mancity.png';
import redBullSalzburgLogo from './assets/salzburg.png';
import manuLogo from './assets/manu.png';
import sevilleLogo from './assets/seville.png';
import barcelonaLogo from './assets/barca.png';
import sportingLogo from './assets/sporting.png';
import sportingCPLogo from './assets/sporting.png';
import celticLogo from './assets/celtic.svg';
import nantesLogo from './assets/nantes.png';
import santosLogo from './assets/santos.png';
import acMilanLogo from './assets/acmilan.png';
import ajaxLogo from './assets/ajax.png';
import asRomaLogo from './assets/roma.png';
import albaceteLogo from './assets/albacete.png';
import alSaddLogo from './assets/alsadd.png';
import anjiLogo from './assets/anji.png';
import argentinosJuniorsLogo from './assets/argentinos.png';
import arsenalLogo from './assets/arsenal.png';
import atleticoLogo from './assets/atletico.png';
import atleticoMadridLogo from './assets/atletico.png';
import bahiaLogo from './assets/bahia.png';
import baselLogo from './assets/bale.png';
import benficaLogo from './assets/benfica.png';
import besiktasLogo from './assets/besiktas.png';
import realBetisLogo from './assets/betis.png';
import bocaJuniorsLogo from './assets/boca.png';
import cannesLogo from './assets/cannes.png';
import celtaVigoLogo from './assets/celta.png';
import chelseaLogo from './assets/chelsea.png';
import dortmundLogo from './assets/dortmund.png';
import evertonLogo from './assets/everton.png';
import fiorentinaLogo from './assets/fiorentina.png';
import flamengoLogo from './assets/flamengo.png';
import genkLogo from './assets/genk.png';
import gremioLogo from './assets/gremio.png';
import hamburgLogo from './assets/hamburg.png';
import independienteLogo from './assets/independiente.png';
import institutoLogo from './assets/instituto.png';
import interMilanLogo from './assets/inter.png';
import milanLogo from './assets/acmilan.png';
import internacionalLogo from './assets/internacional.png';
import bayerLeverkusenLogo from './assets/leverkusen.png';
import lilleLogo from './assets/lille.png';
import liverpoolLogo from './assets/liverpool.png';
import losAngelesAztecsLogo from './assets/losangelesaztecs.png';
import lyonLogo from './assets/lyon.png';
import manCityLogo from './assets/mancity.png';
import marseilleLogo from './assets/marseille.png';
import metzLogo from './assets/metz.png';
import monacoLogo from './assets/monaco.png';
import napoliLogo from './assets/naples.png';
import nottinghamForestLogo from './assets/nottingham.png';
import palermoLogo from './assets/palermo.png';
import palmeirasLogo from './assets/palmeiras.png';
import portoLogo from './assets/porto.png';
import psvEindhovenLogo from './assets/psv.png';
import psvLogo from './assets/psv.png';
import romaLogo from './assets/roma.png';
import saoPauloLogo from './assets/saopaulo.png';
import schalkeLogo from './assets/schalke.png';
import shakhtarLogo from './assets/shaktar.png';
import southamptonLogo from './assets/southampton.png';
import visselKobeLogo from './assets/vissel.png';
import werderLogo from './assets/werder.png';
import wolfsburgLogo from './assets/wolfsburg.png';
import zaragozaLogo from './assets/zaragoza.png';
import leedsLogo from './assets/leeds.png';
import newcastleLogo from './assets/newcastle.png';
import astonVillaLogo from './assets/astonvilla.png';
import levanteLogo from './assets/levante.png';
import fenerbahceLogo from './assets/fenerbahce.png';
import westhamLogo from './assets/westham.png';
import villarealLogo from './assets/villareal.png';
import lazioLogo from './assets/lazio.png';
import genoaLogo from './assets/genoa.png';
import fluminenseLogo from './assets/fluminense.png';
import olympiakosLogo from './assets/olympiakos.png';


import croatieLogo from './assets/croatie.png';
import franceLogo from './assets/France.avif';
import allemagneLogo from './assets/allemagne.png';
import portugalLogo from './assets/portugal.png';
import argentineLogo from './assets/argentine.png';
import belgiqueLogo from './assets/belgique.png';
import espagneLogo from './assets/espagne.png';
import paysbasLogo from './assets/paysbas.png';
import bresilLogo from './assets/bresil.png';
import colombieLogo from './assets/colombie.png';
import coréeLogo from './assets/coree.webp';
import armenieLogo from './assets/armenie.png';
import irlandeLogo from './assets/irlande.webp';
import senegalLogo from './assets/senegal.png';
import bosnieLogo from './assets/bosnie.png';
import angleterreLogo from './assets/angleterre.png';
import costaricaLogo from './assets/costarica.png';
import italieLogo from './assets/italie.png';




import ('./AppLangue.css')


const playersEn = [
  {
    clubs: [bordeauxLogo, juventusLogo, realMadridLogo],
    name: ['Zidane', 'Zinedine Zidane'],
    nationality: [franceLogo],
    position: 'midfielder',
  },
  {
    clubs: [dynamoLogo, tottenhamLogo, realMadridLogo],
    name: ['Modric'],
    nationality: [croatieLogo],
    position: 'midfielder',
  },
  {
    clubs: [bayernLogo, realMadridLogo],
    name: ['Kroos'],
    nationality: [allemagneLogo],
    position: 'midfielder',
  },
  {
    clubs: [sevilleLogo, realMadridLogo, psgLogo],
    name: ['Ramos'],
    nationality: [espagneLogo],
    position: 'defender',
  },
  {
    clubs: [sportingLogo, manuLogo, realMadridLogo, juventusLogo],
    name: ['Ronaldo'],
    nationality: [portugalLogo],
    position: 'forward',
  },
  {
    clubs: [barcelonaLogo, psgLogo],
    name: ['Messi'],
    nationality: [argentineLogo],
    position: 'forward',
  },
  {
    clubs: [lilleLogo, chelseaLogo, realMadridLogo],
    name: ['Hazard'],
    nationality: [belgiqueLogo],
    position: 'forward',
  },
  {
    clubs: [manuLogo, juventusLogo, manuLogo, juventusLogo],
    name: ['Pogba'],
    nationality: [franceLogo],
    position: 'midfielder',
  },
  {
    clubs: [chelseaLogo, wolfsburgLogo, manCityLogo],
    name: ['De Bruyne'],
    nationality: [belgiqueLogo],
    position: 'midfielder',
  },
  {
    clubs: [schalkeLogo, werderLogo, realMadridLogo, arsenalLogo],
    name: ['Ozil'],
    nationality: [allemagneLogo],
    position: 'midfielder',
  },
  {
    clubs: [liverpoolLogo, realMadridLogo, bayernLogo],
    name: ['Xabi Alonso'],
    nationality: [espagneLogo],
    position: 'midfielder',
  },
  {
    clubs: [psvLogo, chelseaLogo, realMadridLogo, bayernLogo],
    name: ['Robben'],
    nationality: [paysbasLogo],
    position: 'forward',
  },
  {
    clubs: [ marseilleLogo, bayernLogo, fiorentinaLogo],
    name: ['Ribery'], 
    nationality: [franceLogo],
    position: 'forward',
  },
  {
    clubs: [gremioLogo, psgLogo, barcelonaLogo, acMilanLogo, flamengoLogo],
    name: ['Ronaldinho'],
    nationality: [bresilLogo],
    position: 'midfielder',
  },
  {
    clubs: [lyonLogo, realBetisLogo],
    name: ['Fekir'],
    nationality: [franceLogo],
    position: 'midfielder',
  },
  {
    clubs: [barcelonaLogo, visselKobeLogo],
    name: ['Iniesta'],
    nationality: [espagneLogo],
    position: 'midfielder',
  },
  {
    clubs: [barcelonaLogo, alSaddLogo],
    name: ['Xavi'],
    nationality: [espagneLogo],
    position: 'midfielder',
  },
  {
    clubs: [schalkeLogo, manCityLogo, bayernLogo],
    name: ['Sané'],
    nationality: [allemagneLogo],
    position: 'forward',
  },
  {
    clubs: [metzLogo, redBullSalzburgLogo, southamptonLogo, liverpoolLogo, bayernLogo],
    name: ['Mané'],
    nationality: [senegalLogo],
    position: 'forward',
  },
  {
    clubs: [argentinosJuniorsLogo, bocaJuniorsLogo, barcelonaLogo, napoliLogo],
    name: ['Maradona'],
    nationality: [argentineLogo],
    position: 'midfielder',
  },
  {
    clubs: [ajaxLogo, barcelonaLogo, losAngelesAztecsLogo],
    name: ['Cruyff'],
    nationality: [paysbasLogo],
    position: 'forward',
  },
  {
    clubs: [ajaxLogo, milanLogo],
    name: ['Van Basten'],
    nationality: [paysbasLogo],
    position: 'forward',
  },
  {
    clubs: [ajaxLogo, sportingCPLogo, milanLogo],
    name: ['Rijkaard'],
    nationality: [paysbasLogo],
    position: 'midfielder',
  },
  {
    clubs: [ portoLogo, monacoLogo, realMadridLogo, bayernLogo, evertonLogo],
    name: ['James Rodriguez'],
    nationality: [colombieLogo],
    position: 'midfielder',
  },
  {
    clubs: [palmeirasLogo, interMilanLogo, realMadridLogo, fenerbahceLogo],
    name: ['Roberto Carlos'],
    nationality: [bresilLogo],
    position: 'defender',
  },
  {
    clubs: [psvEindhovenLogo, manuLogo, realMadridLogo],
    name: ['Van Nistelrooy'],
    nationality: [paysbasLogo],
    position: 'forward',
  },
  {
    clubs: [bayernLogo, atleticoMadridLogo, juventusLogo],
    name: ['Mandzukic'],
    nationality: [croatieLogo],
    position: 'forward',
  },
  {
    clubs: [hamburgLogo, bayerLeverkusenLogo, tottenhamLogo],
    name: ['Son'],
    nationality: [coréeLogo],
    position: 'forward',
  },
  {
    clubs: [fluminenseLogo, realMadridLogo, olympiakosLogo],
    name: ['Marcelo'],
    nationality: [bresilLogo],
    position: 'defender',
  },
  {
    clubs: [bahiaLogo, sevilleLogo, barcelonaLogo, juventusLogo, psgLogo, saoPauloLogo],
    name: ['Dani Alves'],
    nationality: [bresilLogo],
    position: 'defender',
  },
  {
    clubs: [independienteLogo, atleticoMadridLogo, manCityLogo, barcelonaLogo],
    name: ['Agüero','Aguero'],
    nationality: [argentineLogo],
    position: 'forward',
  },
  {
    clubs: [benficaLogo, monacoLogo, manCityLogo],
    name: ['Bernardo Silva'],
    nationality: [portugalLogo],
    position: 'midfielder',
  },
  {
    clubs: [shakhtarLogo, dortmundLogo, manuLogo, arsenalLogo, romaLogo],
    name: ['Mkhitaryan'],
    nationality: [armenieLogo],
    position: 'midfielder',
  },
  {
    clubs: [nottinghamForestLogo, manuLogo, celticLogo],
    name: ['Keane'],
    nationality: [irlandeLogo],
    position: 'midfielder',
  },
  {
    clubs: [nantesLogo, celtaVigoLogo, realMadridLogo, chelseaLogo, parisSGLogo],
    name: ['Makélélé'],
    nationality: [franceLogo],
    position: 'midfielder',
  },
  {
    clubs: [cannesLogo, acMilanLogo, arsenalLogo, juventusLogo, interMilanLogo, manCityLogo],
    name: ['Vieira'],
    nationality: [franceLogo],
    position: 'midfielder',
  },
  {
    clubs: [institutoLogo, palermoLogo, juventusLogo, romaLogo],
    name: ['Dybala'],
    nationality: [argentineLogo],
    position: 'forward',
  },
  {
    clubs: [metzLogo, genkLogo, napoliLogo],
    name: ['Koulibaly'],
    nationality: [senegalLogo],
    position: 'defender',
  },
  {
    clubs: [internacionalLogo, romaLogo, liverpoolLogo],
    name: ['Alisson'],
    nationality: [bresilLogo],
    position: 'goalkeeper',
  },
  {
    clubs: [monacoLogo, juventusLogo, arsenalLogo, barcelonaLogo],
    name: ['Henry'],
    nationality: [franceLogo],
    position: 'forward',
  },
  {
    clubs: [metzLogo, lyonLogo, asRomaLogo, juventusLogo, barcelonaLogo, besiktasLogo],
    name: ['Pjanic'],
    nationality: [bosnieLogo],
    position: 'midfielder',
  },
  
  {
    clubs: [ajaxLogo, manuLogo],
    name: ['Van de Beek'],
    nationality: [paysbasLogo],
    position: 'midfielder',
  },
  {
    clubs: [santosLogo, barcelonaLogo, psgLogo],
    name: ['Neymar'],
    nationality: [bresilLogo],
    position: 'forward',
  },
  {
    clubs: [psvLogo, manuLogo, lyonLogo, barcelonaLogo],
    name: ['Depay'],
    nationality: [paysbasLogo],
    position: 'forward',
  },
  
  {
    clubs: [shakhtarLogo, anjiLogo, chelseaLogo, arsenalLogo],
    name: ['Willian'],
    nationality: [bresilLogo],
    position: 'midfielder',
  },
  {
    clubs: [leedsLogo, newcastleLogo, astonVillaLogo, mancityLogo, liverpoolLogo],
    name: ['Milner'],
    nationality: {angleterreLogo},
    position: 'midfielder',
  },
{
    clubs: [saoPauloLogo, zaragozaLogo, palmeirasLogo, romaLogo, milanLogo],
    name: ['Cafu'],
    nationality: [bresilLogo],
    position: 'defender',
  },
  {
    clubs: [albaceteLogo, levanteLogo, realMadridLogo, psgLogo],
    name: ['Navas'],
    nationality: [costaricaLogo],
    position: 'goalkeeper',
  },
  {
    clubs: [ajaxLogo, atleticoLogo, southamptonLogo, tottenhamLogo],
    name: ['Alderweireld'],
    nationality: [belgiqueLogo],
    position: 'defender',
  },
  {
    clubs: [baselLogo, schalkeLogo, sevilleLogo, barcelonaLogo, sevilleLogo],
    name: ['Rakitic'],
    nationality: [croatieLogo],
    position: 'midfielder',
  }

  
  
  
  
  
  
];

export default playersEn;
