import React, { useState, useEffect } from 'react';
import { format, getDay } from 'date-fns';



import './GuessDay.css';

import playersGuessDay from './VarGuessDay';

const GuessDay = () => {
  

  const [currentPlayerIndex, setCurrentPlayerIndex] = useState(0);
  const [currentPlayer, setCurrentPlayer] = useState(playersGuessDay[currentPlayerIndex]);
  const [guess, setGuess] = useState('');
  const [isCorrectGuess, setIsCorrectGuess] = useState(false);
  const [isIncorrectGuess, setIsIncorrectGuess] = useState(false);
  const [showHints, setShowHints] = useState(false);
  const [hints, setHints] = useState([]);
  const [areHintsExhausted, setAreHintsExhausted] = useState(false);
  const [showAllHintsUsedMessage, setShowAllHintsUsedMessage] = useState(false);

  const handleGuessChange = (event) => {
    setGuess(event.target.value);
  };

  const handleGuessSubmit = (event) => {
    event.preventDefault();

    const validNames = currentPlayer.names.map((name) => name.toLowerCase().replace(/\s/g, ''));
    const lowercaseGuess = guess.toLowerCase().replace(/\s/g, '');
    setAreHintsExhausted(false); 
    if (validNames.includes(lowercaseGuess)) {
      setIsCorrectGuess(true);
      setIsIncorrectGuess(false);
      setGuess('');
      setShowHints(false);
      setHints([]);

      setTimeout(() => {
        setIsCorrectGuess(false);
      }, 2000);
    } else {
      setIsCorrectGuess(false);
      setIsIncorrectGuess(true);
      setGuess('');

      setTimeout(() => {
        setIsIncorrectGuess(false);
      }, 2000);
    }
  };

  const handleHintClick = () => {
    if (hints.length === 2) {
      setAreHintsExhausted(true);
      setShowAllHintsUsedMessage(true);
      setTimeout(() => {
        setShowAllHintsUsedMessage(false);
      }, 2000);
    } else {
      setShowHints(true);

      const availableHints = ['nationality', 'position'];
      const remainingHints = availableHints.filter((hint) => !hints.includes(hint));
      const randomHint = remainingHints[Math.floor(Math.random() * remainingHints.length)];

      setHints([...hints, randomHint]);
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();

    const dayOffset = 0;

    setCurrentPlayerIndex((currentDay + dayOffset) % playersGuessDay.length);
  }, []);

  useEffect(() => {
    setCurrentPlayer(playersGuessDay[currentPlayerIndex]);
  }, [currentPlayerIndex]);

  return (
    <div className="GuessDay">
      <h1>Devinez le joueur du jour !</h1>
      <p className='result'>
          {isCorrectGuess && <p className="correct">Correct !</p>}
          {isIncorrectGuess && <p className="incorrect">Faux !</p>}</p>
          
      <p>Parcours:</p>
      {currentPlayer.clubs.map((club, index) => (
        <img key={`club-logo-${index}`} src={club} alt={`club-Logo ${club}`} className="logo" />
      ))}
      {showHints && (
        <div className="column-container">
          {hints.includes('nationality') && (
            <p className="hint">
              Indice: Nationalité -{' '}
              {currentPlayer.nationality.map((nationality, index) => (
                <img
                  key={`logo-${index}`}
                  src={nationality}
                  alt={`Logo ${nationality}`}
                  className="logo-nationality"
                />
              ))}
            </p>
          )}
          {hints.includes('position') && (
            <p className="hint">Indice : Poste - {currentPlayer.position}</p>
          )}
        </div>
      )}
      <form onSubmit={handleGuessSubmit}>
        <input
          type="text"
          value={guess}
          onChange={handleGuessChange}
          placeholder="Entrez le nom du joueur"
        />
        <button className="guessbutton" type="submit">
          Deviner
        </button>
      </form>
      {showAllHintsUsedMessage && <p>Tous les indices ont été utilisés.</p>}
      <button className="tipsbutton" onClick={handleHintClick} disabled={areHintsExhausted}>
        Indice
      </button>
    </div>
  );
};

export default GuessDay;
