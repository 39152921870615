import React, { useState, useEffect } from 'react';
import AppFr from './AppFr';
import AppEn from './AppEn';
import './Navbar.css';
import GuessDay from './GuessDay';
import GuessDayEn from './GuessDayEn';
import Informations from './Informations';
import InformationsEn from './InformationsEn';
import GuessImpossible from './GuessImpossible';
import GuessStreak from './GuessStreak';


const getBrowserLanguage = () => {
  const navigatorLanguage = window.navigator.language || window.navigator.userLanguage;
  return navigatorLanguage.split('-')[0]; 
};

const Navbar = () => {
  const [lang, setLang] = useState(getBrowserLanguage() === 'en' ? 'en' : 'fr');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState('information');
  const [showGuessOfDay, setShowGuessOfDay] = useState(false);

  const handleLanguageChange = (e) => {
    setLang(e.target.value);
  };

  const handleDropdownToggle = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleGuessDayClick = () => {
    setShowGuessOfDay(true);
  };

  const handleBackToAppClick = () => {
    setShowGuessOfDay(false);
  };

  const renderApp = () => {
    if (currentPage === 'information' && lang === 'fr') {
      return <Informations />;
    } else if (currentPage === 'information' && lang === 'en') {
      return <InformationsEn />;
    } else if (currentPage === 'game' && lang === 'fr') {
      return <AppFr />;
    } else if (currentPage === 'game' && lang === 'en') {
      return <AppEn />;
    } else if (currentPage === 'gameday' && lang === 'fr') {
      return <GuessDay />;
    } else if (currentPage === 'gameday' && lang === 'en') {
      return <GuessDayEn />;}
  //   } else if (currentPage === 'gameimpossible' && lang === 'fr') {
  //   return <GuessImpossible />;
  // }else if (currentPage === 'gameimpossible' && lang === 'en') {
  //   return <GuessImpossible />;
  // }else if (currentPage === 'gamestreak' && lang === 'fr') {
  //   return <GuessStreak />;
  // }
    
  };

  return (
    <div>
      <nav className="navbar">
        <h1>Guess The Football Player</h1>
        <ul>
          <li>
            <button onClick={() => setCurrentPage('information')}>
              {lang === 'fr' ? 'Informations' : 'Informations'}
            </button>
          </li>
          <li>
            <button onClick={() => setCurrentPage('game')}>
              {lang === 'fr' ? 'Jouer au jeu' : 'Play the Game'}
            </button>
          </li>
          <li>
            <label htmlFor="language">
              {lang === 'fr' ? 'Langue:' : 'Language:'}
            </label>
            <select
              id="language"
              value={lang}
              onChange={handleLanguageChange}
            >
              <option value="fr">Français</option>
              <option value="en">English</option>
            </select>
          </li>
          <li>
            <button onClick={() => setCurrentPage('gameday')}>
              {lang === 'fr' ? 'Guess of the day' : 'Guess of the day'}
            </button>
          </li>
          {/* <li>
            <button onClick={() => setCurrentPage('gameimpossible')}>
              {lang === 'fr' ? 'Devinettes Impossibles' : 'Impossible Guesses'}
            </button>
          </li>
          <li>
            <button onClick={() => setCurrentPage('gamestreak')}>
              {lang === 'fr' ? 'Streak Guess' : 'Streak Guess'}
            </button>
          </li> */}
        </ul>
      </nav>

      {renderApp()}
    </div>
  );
};

export default Navbar;
