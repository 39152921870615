import React from 'react';
import './Informations.css'; // Import your CSS file for styling
import guessfaux from './assets/guessfaux.PNG';
import guessright from './assets/guessright.PNG';
import guessmessi from './assets/guessmessi.PNG';
import guessronaldo from './assets/guessronaldo.PNG';


const Informations = () => {
  return (
    <div className="information-page">
      <div className="information-content">
        <h2>À propos de Guess The Football Player</h2>
        <p>
          Bienvenue sur Guess The Football Player, un jeu amusant qui teste vos connaissances sur les joueurs de football en se basant sur leur parcours en club.
        </p>
        <p>
          Comment jouer :
        </p>
        <ul>
          <li>Vous verrez une liste de clubs auxquels le joueur a appartenu au cours de sa carrière.</li>
          <li>Votre tâche est de deviner le nom du joueur en utilisant les indices fournis.</li>
          <li>Entrez simplement le nom de famille du joueur dans le champ de texte et cliquez sur "Devinez".</li>
        </ul>
        <p>
          Exemple :
        </p>
        <img
          src={guessmessi} 
          alt="Guess Example"
          className="information-image"
        />
        <img
          src={guessfaux} 
          alt="Guess Example"
          className="information-image"
        />
        <img
          src={guessronaldo} 
          alt="Guess Example"
          className="information-image"
        />
        <img
          src={guessright} 
          alt="Guess Example"
          className="information-image"
        />
        <p>
          Bonne chance et amusez-vous bien en jouant !
        </p>
      </div>
    </div>
  );
};


export default Informations;
