import React, { useState, useEffect } from 'react';
import playersFr from './Var';

function GuessStreak() {
  const [usedPlayers, setUsedPlayers] = useState([]);
  const [currentPlayerIndex, setCurrentPlayerIndex] = useState(0);
  const [guess, setGuess] = useState('');
  const [isCorrectGuess, setIsCorrectGuess] = useState(false);
  const [score, setScore] = useState(0);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    setUsedPlayers([]);
    setCurrentPlayerIndex(getRandomPlayerIndex());
  }, []);

  const getRandomPlayerIndex = () => {
    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * playersFr.length);
    } while (usedPlayers.includes(randomIndex));
    return randomIndex;
  };

  const currentPlayer = playersFr[currentPlayerIndex];

  const handleGuessChange = (event) => {
    setGuess(event.target.value);
  };

  const handleGuessSubmit = (event) => {
    event.preventDefault();
    const lowercaseGuess = guess.toLowerCase();
    const validNames = currentPlayer.name.map((name) => name.toLowerCase());
    const isGuessCorrect = validNames.includes(lowercaseGuess);

    const answerObject = {
      playerName: currentPlayer.name.join('/'), // Mettez le nom du joueur comme une chaîne unique s'il a plusieurs noms
      isCorrect: isGuessCorrect,
    };

    // Mettez à jour le tableau answers avec les trois dernières réponses
    setAnswers((prevAnswers) => [...prevAnswers.slice(-2), answerObject]);

    if (isGuessCorrect) {
      setScore(score + 1);
    }

    setGuess('');
    setUsedPlayers([...usedPlayers, currentPlayerIndex]);

    if (usedPlayers.length >= 2) {
      // La partie est terminée, pas besoin de changer le joueur
      return;
    } else {
      setCurrentPlayerIndex(getRandomPlayerIndex());
    }
  };

  return (
    <div className="App">
      {usedPlayers.length < 3 ? (
        <div>
          <h1>Devinez le footballeur</h1>
          <p>Parcours :</p>
          {currentPlayer.clubs.map((club, index) => (
            <img key={`club-logo-${index}`} src={club} alt={`club-Logo ${club}`} className="logo" />
          ))}
          <form onSubmit={handleGuessSubmit}>
            <input
              type="text"
              value={guess}
              onChange={handleGuessChange}
              placeholder="Entrez le nom du joueur"
            />
            <button className="guessbutton" type="submit">
              Deviner
            </button>
          </form>
          {!isCorrectGuess && usedPlayers.length > 0}
        </div>
      ) : (
        <div>
          <h1>Partie terminée</h1>
          <h2>Votre score final est : {score}/3</h2>
          <h3>Réponses :</h3>
          <ul>
            {answers.map((answer, index) => (
              <li key={index}>
                {answer.playerName} : {answer.isCorrect ? 'Correct' : 'Incorrect'}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default GuessStreak;
