import Navbar from './Navbar';





function App() {

  

  return (
    <Navbar/>
  );
  
  
}

export default App;
